import React, {Component} from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button"
import ArrowNavigationIcon from "@material-ui/icons/NearMe"
import ArrowForwardThinIcon from "@material-ui/icons/ArrowForward"
import WaveSvg from "../assets/images/Wave.svg"
import {graphql} from "gatsby"
import {getFirstContentfulNode} from "../common/utils/QueryUtil"
import {isValidPhone, isValidSectionField} from "../common/utils/CommonUtils";
import {SegmentAnalytics} from "../common/utils/SegmentAnalytics";
import {SegmentEvents} from "../common/utils/SegmentSpec";
import BreadCrumbs from "../components/bread-crumbs";
import {BranchUtils} from "../common/utils/BranchUtils";
import "./get-started.scss"

const breadCrumbsList  = [

    {
        title: "Home",
        link: "/",
    }

]

class GetStarted extends Component {

    constructor(props) {
        console.log("props", props);
        super(props);
        this.state = {
            phone: "",
            success: false,
            error: false,
        };
        this.pageData = getFirstContentfulNode(this.props,
            "allContentfulGetStartedPage")

    }

    componentDidMount() {
        window.analytics.page("Get Started")
    }

    sendPhone(event, phoneNumber) {
        event.preventDefault();
        const phone = isValidPhone(phoneNumber);
        if (phone) {
            let currentPage = typeof window !== 'undefined'
                ? window.location.pathname : '';
            currentPage = currentPage.replace('/', '');
            if (currentPage === "") {
                currentPage = "home-page"
            }
            let fromPage = currentPage + '-top'

            SegmentAnalytics.track(SegmentEvents.GET_STARTED_WITH_CONFIDANT, {
                phone,
                'formLocation': fromPage,
                appRequested: true,
            });

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'formLocation': fromPage,
                'event': 'GET_THE_APP',
                'phone': phone
            });

            BranchUtils.desktopSendSMS(phone, fromPage, 'Header CTA', fromPage );

            this.setState({
                success: true,
                error: false,
                phone: ""
            })
        } else {
            this.setState({
                error: true,
                success: false,
            })
        }
    };

    render() {
        const {topSection, shortSections, benefits} = this.pageData;
        return (
            <Layout>
                <SEO title="Get Started"/>
                <BreadCrumbs currentPage={"Get Started"} breadCrumbsList={breadCrumbsList}/>
                <div className="common-detailed-header">
                    <div className="common-detailed-inner">

                        <div className="common-detailed-pattern-content">
                            {
                                isValidSectionField(topSection, "title") &&
                                <h2 className="common-detailed-main-head">{topSection.title}</h2>
                            }
                            {
                                isValidSectionField(topSection.description,
                                    "description") &&
                                <p className="common-detailed-para"
                                   dangerouslySetInnerHTML={{__html: topSection.description.description}}></p>
                            }
                            <form method="post" onSubmit={(event) => {
                                this.sendPhone(event, this.state.phone)
                            }}>
                                <div className="get-app-input-wrap">
                                    <div className="input-box">
                                        <TextField
                                            id="phone-input"
                                            className="number-field"
                                            placeholder="Enter your phone number"
                                            value={this.state.phone}
                                            margin="normal"
                                            onChange={event => {
                                                const {value} = event.target;
                                                this.setState({
                                                    phone: value,
                                                    error: false,
                                                    success: false,
                                                })
                                            }}
                                        />
                                    </div>
                                    <Button
                                        className="blue-arrow-btn"
                                        color="primary"
                                        type="submit"
                                    >
                                        Get the App <ArrowNavigationIcon/>
                                    </Button>
                                    {this.state.error ? <p
                                        className="error-msg">Please Enter Valid
                                        Phone</p> : null}
                                    {this.state.success ? <p
                                        className="success-msg">Got it! Sending you a text with the link</p> : null}
                                </div>
                            </form>
                        </div>
                        {topSection && topSection.imageOrVideo && (
                            <div className="common-detailed-image-side">
                                <img
                                    className="common-detailed-hero-img get-started-hero"
                                    src={topSection.imageOrVideo.file.url}
                                    alt="iPhone"/>
                            </div>
                        )}
                    </div>
                </div>


                <div className="get-start-wave-wrapper"
                     style={{backgroundImage: `url(${WaveSvg})`}}>
                    <div className="wave-bg">
                        <div className="get-start-wave-inner">
                            <h2 className="get-start-wave-heading">Explore Our
                                Services</h2>
                            <p className="get-start-wave-Para"> You can book a
                                service on our website or on our app.
                                If you aren't sure where to start, your
                                Matchmaker will start a complimentary
                                conversation with you after you download the
                                app.</p>
                            <div className="wave-service-list">
                                <div className="single-wave-service-box">
                                    <img className="new-feature-image"
                                         src={require(
                                             "../assets/images/provider-prescriber.svg")}
                                         alt="Providers & Prescriber Visual"/>
                                    <Button href="/online-therapy/"
                                            className="new-blue-link"
                                            color="primary">
                                        Providers &
                                        Prescribers <ArrowForwardThinIcon/>
                                    </Button>
                                </div>

                                <div className="single-wave-service-box">
                                    <img className="new-feature-image"
                                         src={require(
                                             "../assets/images/free-support.svg")}
                                         alt="Support Groups Visual"/>
                                    <Button href="/support-groups/"
                                            className="new-blue-link"
                                            color="primary">
                                        Support Groups <ArrowForwardThinIcon/>
                                    </Button>
                                </div>

                                <div className="single-wave-service-box">
                                    <img className="new-feature-image"
                                         src={require(
                                             "../assets/images/self-directed.svg")}
                                         alt="Self-directed Courses Visual"/>
                                    <Button href="/how-to-guides/"
                                            className="new-blue-link"
                                            color="primary">
                                        Self-directed
                                        Courses <ArrowForwardThinIcon/>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="get-start-feature-wrapper">
                    <div className="get-start-feature-inner">
                        {
                            benefits && benefits.map(benefit => (
                                <div key={"benefit-" + benefit.name}
                                     className="single-get-start-feature">
                                    {benefit.image.file.url && (
                                        <img className="single-get-start-img"
                                             src={benefit.image.file.url}
                                             alt="Icon"/>
                                    )}
                                    <div className="get-start-feature-content">
                                        {benefit.name && (
                                            <h2 className="head">{benefit.name}</h2>
                                        )}
                                        {benefit.description && (
                                            <p className="para">{benefit.description}</p>
                                        )}
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className="get-support-wrapper">
                    <div className="get-support-inner">
                        {
                            shortSections.map((section, index) => (
                                <div key={"key-" + section.heading}
                                     className="get-support-single-feature">
                                    {
                                        index % 2 !== 0 && (
                                            <div
                                                className="get-support-img-side">
                                                {section?.image?.file && (
                                                    <img
                                                        className="get-support-image"
                                                        src={section.image.file.url}
                                                        alt="
                                                          Frame"/>
                                                )}
                                            </div>
                                        )
                                    }


                                    <div className="get-support-text-side">

                                        <h2 className="get-support-heading">
                                            {section.heading}
                                        </h2>

                                        {section.description.description && (
                                            <p className="get-support-para"
                                               dangerouslySetInnerHTML={{__html: section.description.description}}>
                                            </p>
                                        )}

                                        {section.link && (
                                            <Button href={`/${section.link}/`}
                                                    className="new-blue-link"
                                                    color="primary">
                                                {section.linkText}
                                                <ArrowForwardThinIcon/>
                                            </Button>
                                        )}
                                    </div>
                                    {
                                        (index === 0 || index % 2 === 0) && (

                                            <div
                                                className="get-support-img-side"
                                                style={{textAlign: "right"}}>

                                                {section?.image?.file && (
                                                    <img
                                                        className="get-support-image"
                                                        src={section.image.file.url}
                                                        alt=" Frame"/>

                                                )}
                                            </div>
                                        )
                                    }

                                </div>
                            ))
                        }
                    </div>
                </div>

                <div className="new-community-model-wrapper" style={{ paddingTop: 20, paddingBottom: 120}}>
                    <div className="new-community-model-inner">
                        <h2 className="new-community-model-text">Confidant Community Payment Model</h2>
                        <p className="community-payment-description">Our "Pay it Forward" approach enables people to access our services for as little as $1 Because your income shouldn't dictate your future.</p>
                        <Button href="/community-payment/" className="new-blue-link" color="primary">
                            Read about the community payment model <ArrowForwardThinIcon/>
                        </Button>
                        <img
                            className="community-model-image"
                            src={require("../assets/images/community-image1.png")}
                            alt="Community Model Image"
                        />
                        {/*<Button href="/community-payment/" className="new-blue-link link-color"  color="primary">Payment
                            <ArrowForwardThinIcon/>
                        </Button>*/}
                    </div>

                </div>
            </Layout>
        )
    }
}

export default GetStarted

export const query = graphql`
query getContentfulGetStartedPage{
   allContentfulGetStartedPage {
    
      nodes {
        id
        topSection {
       
          title
           ... on ContentfulPageTopSection {
          description {
            description
          }
    
          imageOrVideo {    
            file {
              contentType
              url
            }
          }
           }
          }
       
        benefits {
        ... on ContentfulSectionMeta {
          image {
            file {
              contentType
              url
            }
          }
          description
          }
          name
        }
     
        shortSections {
           ... on ContentfulShortSection{
          alignment
          heading
          image {
            file {
              contentType
              url
            }
          }
          description {
            description
          }
          link
          linkText
        }
        }
      }
  }
}`;
